// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-abnormal-page-js": () => import("./../src/templates/AbnormalPage.js" /* webpackChunkName: "component---src-templates-abnormal-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../src/templates/ContactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-gallery-page-js": () => import("./../src/templates/GalleryPage.js" /* webpackChunkName: "component---src-templates-gallery-page-js" */),
  "component---src-templates-hay-page-js": () => import("./../src/templates/HayPage.js" /* webpackChunkName: "component---src-templates-hay-page-js" */),
  "component---src-templates-home-page-js": () => import("./../src/templates/HomePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-international-page-js": () => import("./../src/templates/InternationalPage.js" /* webpackChunkName: "component---src-templates-international-page-js" */),
  "component---src-templates-machinery-page-js": () => import("./../src/templates/MachineryPage.js" /* webpackChunkName: "component---src-templates-machinery-page-js" */)
}

